<template>
  <div>
    <div style="display: flex;height: 40px;color: #969696;">
        <p @click="goBack" > <i class="el-icon-back" @click="goBack"></i>{{ $t("order.toBackOrderLists") }}</p>
        <p style="margin: 0 20px;">|</p>
        <p>{{ $t("menu.product_edit") }}</p>
    </div>
    <el-card class="product-step">
      <el-steps :active="stepActive">
        <el-step
          v-for="(name, index) in stepName"
          :key="`proudct-step-${index}`"
          :title="$t('product.step.' + index)"
          :description="$t('product.stepName.' + name)"
        ></el-step>
      </el-steps>
    </el-card>

    <el-card>
      <el-tabs v-model="tabsActive" stretch>
        <el-tab-pane :label="$t('product.stepName.spu')" name="0">
          <!-- 基础信息 -->
          <spu-form :spuId="id" @success="onSpuSuccess" />
        </el-tab-pane>
        <el-tab-pane
          :label="$t('product.stepName.spec')"
          name="1"
          :disabled="!id"
        >
          <!-- 规格配置 -->
          <spec-form :spuId="id" @success="onSpecSuccess" />
        </el-tab-pane>
        <el-tab-pane
          :label="$t('product.stepName.sku')"
          name="2"
          :disabled="skuTabDisabled"
        >
          <!-- 库存价格 -->
          <sku-form
            v-if="specData && specData.color"
            :spuData="spuData"
            :spuId="id"
            @success="onSkuSuccess"
            @setLadder="onSetLadder"
            :spec-data="specData"
            ref="skuFormComponent"
          />
        </el-tab-pane>
        <el-tab-pane
          :label="$t('product.stepName.tag')"
          name="3"
          :disabled="!id"
        >
          <!-- 标签 -->
          <tag-form :id="id" @success="onTagSuccess" />
        </el-tab-pane>
        <el-tab-pane
          :label="$t('product.stepName.media')"
          name="4"
          :disabled="!id"
        >
          <!-- 图片视频 -->
          <media-form
            v-if="spuData.id"
            :spuData="spuData"
            @success="onMadiaSuccess"
          />
        </el-tab-pane>
        <el-tab-pane
          :label="$t('product.stepName.complete')"
          name="5"
          :disabled="stepActive !== 5"
        >
          <!-- 完成 -->
          <el-result
            icon="success"
            :title="$t('common.optSuccess')"
            :subTitle="$t('product.saved')"
          >
            <template slot="extra">
              <el-button type="info" size="medium" @click="handleBackToList">
                {{ $t("product.backToList") }}
              </el-button>
              <el-button
                type="primary"
                size="medium"
                @click="handleViewProduct"
              >
                {{ $t("product.viewProduct") }}
              </el-button>
            </template>
          </el-result>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import SpuForm from "@/components/ProductEdit/SpuForm";
import SpecForm from "@/components/ProductEdit/SpecForm";
import SkuForm from "@/components/ProductEdit/SkuForm";
import TagForm from "@/components/ProductEdit/TagForm";
import MediaForm from "@/components/ProductEdit/MediaForm";

export default {
  components: { SpuForm, SpecForm, SkuForm, TagForm, MediaForm },
  data() {
    return {
      id: "",
      stepName: ["spu", "spec", "sku", "tag", "media", "complete"],
      stepActive: 0,
      spuData: {},
      specData: {},
      showSkuTabForce: false,
    };
  },
  computed: {
    tabsActive: {
      get() {
        return String(this.stepActive);
      },
      set(newVal) {
        this.stepActive = Number(newVal);
      },
    },
    skuTabDisabled() {
      if (this.showSkuTabForce) {
        return false;
      } else if (!this.id || !this.spuData.skuLadderList) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.id = this.$route.query.id;
    // console.log("created-id:", this.id);
  },
  methods: {
    goBack(){
        const filters = this.$store.state.filters;
        this.$router.push({
        path: '/product/list',
        query: {
          searchInfo: filters.searchInfo,
          page:filters.page
        }
      });
    },
    onSpuSuccess(spuData, goNextStep = true) {
      console.log("onSpuSuccess-spuData:", spuData);
      if (spuData && spuData.id) {
        this.spuData = spuData;
        this.$nextTick(() => {
          if (!this.id) {
            this.id = spuData.id;
            this.$router.replace("/product/edit?id=" + spuData.id);
          }
          goNextStep && this.stepActive++;
        });
      }
    },
    onSpecSuccess(specData, goNextStep = true) {
      console.log("onSpecSuccess-specData:", specData);
      // this.$refs["skuFormComponent"].setSpec(specData);
      if (specData) {
        this.specData = specData;
        goNextStep && this.stepActive++;
        this.$nextTick(() => {
          this.$refs["skuFormComponent"].makeSkuData();
        });
      }
    },
    onSetLadder(ladderData) {
      console.log("onSetLadder-ladderData:", ladderData);
      if (ladderData.length) {
        this.showSkuTabForce = true;
      }
    },
    onSkuSuccess(skuData) {
      console.log("onSkuSuccess-skuData:", skuData);
      if (skuData) {
        this.showSkuTabForce = true;
        this.stepActive++;
      }
    },
    onTagSuccess(tagData) {
      console.log("onTagSuccess-tagData:", tagData);
      if (tagData) {
        this.stepActive++;
      }
    },
    onMadiaSuccess(mediaData) {
      console.log("onMadiaSuccess-mediaData:", mediaData);
      if (mediaData) {
        this.stepActive++;
      }
    },
    handleBackToList() {
      this.$router.replace("/product/list");
    },
    handleViewProduct() {
      if (process.env.NODE_ENV === "production") {
        window.open("https://www.sokogate.com/v2/product/detail?id=" + this.id);
      } else {
        window.open(
          "http://sokogate.lenneth.cn/v2/product/detail?id=" + this.id
        );
      }
    },
  },
};
</script>

<style>
.product-step {
  margin-bottom: 10px;
}
</style>